























































import {
	Component,
	Vue
} from "vue-property-decorator";
import {
	apiUserLabelList,
	apiUserLabelDel
} from "@/api/user/user"
import {
	LabelLists_Req
} from "@/api/user/user.d.ts"
import {
	PageMode
} from "@/utils/type"
import {
	RequestPaging
} from "@/utils/util"
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from "@/components/ls-pagination.vue"
import ExportData from '@/components/export-data/index.vue'
@Component({
	components: {
		LsDialog,
		LsPagination,
		ExportData,
	},
})
export default class userTag extends Vue {
	/** S Data **/
	// 分页请求
	pager: RequestPaging = new RequestPaging()
	// 查询表单
	form: LabelLists_Req = {
		name: "", //标签名称
	};
	// 被选中的
	multipleSelection = []
	
	apiUserLabelList = apiUserLabelList
	/** E Data **/

	/** S Methods **/
	// 重置搜索框内容
	onReset() {
		this.form.name = ""
		this.getUserLabelList()
	}
	// 获取用户标签列表
	getUserLabelList() {
		// 分页请求
		this.pager
			.request({
				callback: apiUserLabelList,
				params: this.form
			})
			.catch(() => {
				this.$message.error("数据请求失败，刷新重载")
			})

	}
	// 新增用户标签
	onUserLabelAdd() {
		this.$router.push({
			path: "/user/tag_edit",
			query: {
				mode: PageMode["ADD"],
			},
		});
	}
	// 编辑用户标签
	onUserLabelEdit(item: any) {
		this.$router.push({
			path: "/user/tag_edit",
			query: {
				mode: PageMode["EDIT"],
				id: item.id,
			},
		});
	}

	// 删除用户标签
	onUserLabelDel(item: any) {
		apiUserLabelDel({
			ids: [item.id]
		}).then(() => {
			this.getUserLabelList();
			this.$message.success("删除成功!");
		}).catch(() => {
			//this.$message.error("删除失败")
		})
	}
	// 批量删除
	onUserLabelDelAll() {
		let idArray: Array<Object> = []
		if(this.multipleSelection.length <= 0) {
			this.$message.error("请选择要删除的标签!");
			return
		}
		this.multipleSelection.forEach((item: any) => {
			idArray = [...idArray, item.id]
		})
		apiUserLabelDel({
			ids: idArray
		}).then(() => {
			this.getUserLabelList();
			this.$message.success("删除成功!");
		}).catch(() => {
			//this.$message.error("删除失败")
		})
	}
	// 导出用户标签
	onExportUserLabel() {}
	handleSelectionChange(val: any) {
		this.multipleSelection = val;
	}
	getRowKeys(row: any) {
		return row.id
	}
	/** E Methods **/
	/** S Life Cycle **/
	created() {
		this.getUserLabelList();
	}
	/** E Life Cycle **/
}
